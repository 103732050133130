/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/modal-video@2.4.8/js/jquery-modal-video.min.js
 * - /npm/smartphoto@1.6.5/js/jquery-smartphoto.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
